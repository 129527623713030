<template>
  <v-timeline clipped dense>
    <v-slide-x-transition group>
      <v-timeline-item v-for="(log, idx) in logs" :key="log.id" class="mb-4" small right>
        <v-row justify="space-between">
          <v-col cols="12">
            <v-card v-if="idx==0" color="yellow lighten-4" class="mb-4 p-0 elevation-10">

              <v-card-title class="grey lighten-2 py-1">
                <p class="body-1 mb-0">
                  <b>
                    [{{ log.role_name }}]<br/>{{ log.posted_by_name }}
                  </b>
                </p>
                <v-spacer></v-spacer>
                <p class="body-2 mb-0" style="text-align: right;">
                  {{ log.date | formatDateDayMonth }}<br/>{{ log.date | formatTimeShort }}
                </p>
              </v-card-title>
              <v-spacer></v-spacer>
              <v-card-text>
                <p class="body-1"><b>{{ log.description }}</b></p>
              </v-card-text>
            </v-card>
            <v-card v-else  class="p-0 elevation-6">

              <v-card-title class="primary lighten-5 py-1">
                <p class="body-1 mb-0">
                  <b>
                    [{{ log.role_name }}]<br/>{{ log.posted_by_name }}
                  </b>
                </p>
                <v-spacer></v-spacer>
                <p class="body-2 mb-0" style="text-align: right;">
                  {{ log.date | formatDateDayMonth }}<br/>{{ log.date | formatTimeShort }}
                </p>
              </v-card-title>
              <v-spacer></v-spacer>
              <v-card-text>
                <p class="body-1">{{ log.description }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-slide-x-transition>
  </v-timeline>
</template>
<script>
export default {
  name: "Log",
  props: {
    logs: Array,
  },
  components: {},
  async mounted() {

  },
  data() {
    return {};
  },
};
</script>