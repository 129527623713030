var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-timeline",
    { attrs: { clipped: "", dense: "" } },
    [
      _c(
        "v-slide-x-transition",
        { attrs: { group: "" } },
        _vm._l(_vm.logs, function (log, idx) {
          return _c(
            "v-timeline-item",
            {
              key: log.id,
              staticClass: "mb-4",
              attrs: { small: "", right: "" },
            },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      idx == 0
                        ? _c(
                            "v-card",
                            {
                              staticClass: "mb-4 p-0 elevation-10",
                              attrs: { color: "yellow lighten-4" },
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "grey lighten-2 py-1" },
                                [
                                  _c("p", { staticClass: "body-1 mb-0" }, [
                                    _c("b", [
                                      _vm._v(
                                        " [" + _vm._s(log.role_name) + "]"
                                      ),
                                      _c("br"),
                                      _vm._v(_vm._s(log.posted_by_name) + " "),
                                    ]),
                                  ]),
                                  _c("v-spacer"),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "body-2 mb-0",
                                      staticStyle: { "text-align": "right" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatDateDayMonth")(
                                              log.date
                                            )
                                          )
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatTimeShort")(log.date)
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              _c("v-card-text", [
                                _c("p", { staticClass: "body-1" }, [
                                  _c("b", [_vm._v(_vm._s(log.description))]),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "v-card",
                            { staticClass: "p-0 elevation-6" },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "primary lighten-5 py-1" },
                                [
                                  _c("p", { staticClass: "body-1 mb-0" }, [
                                    _c("b", [
                                      _vm._v(
                                        " [" + _vm._s(log.role_name) + "]"
                                      ),
                                      _c("br"),
                                      _vm._v(_vm._s(log.posted_by_name) + " "),
                                    ]),
                                  ]),
                                  _c("v-spacer"),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "body-2 mb-0",
                                      staticStyle: { "text-align": "right" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatDateDayMonth")(
                                              log.date
                                            )
                                          )
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatTimeShort")(log.date)
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              _c("v-card-text", [
                                _c("p", { staticClass: "body-1" }, [
                                  _vm._v(_vm._s(log.description)),
                                ]),
                              ]),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }