<template>
  <div><embed :src="url" :width="width" :height="height" /></div>
</template>

<script>
import { getSecuredFile } from "@/core/services/helper.service";

export default {
  name: "SecuredMedia",
  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      url: "",
    }
  },
  async created() {
    this.url = await getSecuredFile(this.src);
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
</style>
