var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "12", md: "12", lg: "12", xl: "12" } },
        [
          _c(
            "div",
            [
              _c(
                "v-snackbar",
                {
                  attrs: { color: _vm.snackbar.color, top: "" },
                  model: {
                    value: _vm.snackbar.visible,
                    callback: function ($$v) {
                      _vm.$set(_vm.snackbar, "visible", $$v)
                    },
                    expression: "snackbar.visible",
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "no-gutters": "",
                        align: "center",
                        justify: "center",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [_c("v-icon", [_vm._v(_vm._s(_vm.snackbar.icon))])],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "10" } }, [
                        _c("div", { staticClass: "mx-3" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "snackbar-title font-weight-bold mb-0",
                            },
                            [_vm._v(" " + _vm._s(_vm.snackbar.title) + " ")]
                          ),
                          _c("p", { staticClass: "snackbar-subtitle mb-0" }, [
                            _vm._v(" " + _vm._s(_vm.snackbar.text) + " "),
                          ]),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.snackbar.visible = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "8", md: "6", sm: "12" } },
                    [
                      _c(
                        "b-card",
                        { attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-card-text",
                            [
                              _c("SecuredMedia", {
                                attrs: {
                                  src: _vm.fileSource,
                                  width: "100%",
                                  height: _vm.pdfHeight,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "4", md: "6", sm: "12" } },
                    [
                      _c(
                        "v-tabs",
                        { attrs: { grow: "" } },
                        [
                          _c("v-tab", [_vm._v("Tindakan")]),
                          _c("v-tab", [_vm._v("Maklumat")]),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-2 px-0",
                                          attrs: {
                                            cols: "12",
                                            lg: "12",
                                            md: "12",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              outlined: "",
                                              name: "remarks",
                                              label:
                                                "Tinggalkan komen (jika ada)",
                                            },
                                            model: {
                                              value: _vm.remarks,
                                              callback: function ($$v) {
                                                _vm.remarks = $$v
                                              },
                                              expression: "remarks",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "end",
                                            justify: "end",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-4",
                                              attrs: {
                                                color: "warning",
                                                disabled: _vm.disableKIVBtn,
                                                loading: _vm.isLoadingKIV,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.proceed(
                                                    1,
                                                    "KIV",
                                                    "Anda pasti?"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [_vm._v("KIV")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-4",
                                              attrs: {
                                                color: "error",
                                                disabled:
                                                  _vm.disabledRejectedBtn,
                                                loading: _vm.isLoadingReject,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.proceed(
                                                    2,
                                                    "Tolak",
                                                    "Anda pasti ingin menolak permohonan ini?"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [_vm._v("Tolak")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mx-4",
                                              attrs: {
                                                color: "primary",
                                                disabled: _vm.disableApprBtn,
                                                loading: _vm.isLoadingApprove,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.proceed(
                                                    3,
                                                    "Lulus",
                                                    "Anda pasti ingin meluluskan permohonan ini?"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                },
                                                [_vm._v("Lulus")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [_c("Log", { attrs: { logs: _vm.logs } })],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs4: "",
                                                    sm4: "",
                                                    md4: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col pt-0 pb-0",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "subtitle-1",
                                                        },
                                                        [_vm._v("No. rujukan")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs8: "",
                                                    sm8: "",
                                                    md8: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col pt-0 pb-0",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "subtitle-1 font-weight-bold mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.application
                                                                  .ref_no
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs4: "",
                                                    sm4: "",
                                                    md4: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col pt-0 pb-0",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "subtitle-1",
                                                        },
                                                        [_vm._v("Nama pemohon")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs8: "",
                                                    sm8: "",
                                                    md8: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col pt-0 pb-0",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "subtitle-1 font-weight-bold mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.application
                                                                  .member
                                                                  .full_name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs4: "",
                                                    sm4: "",
                                                    md4: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col pt-0 pb-0",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "subtitle-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Tarikh permohonan dihantar"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs8: "",
                                                    sm8: "",
                                                    md8: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "col pt-0 pb-0",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "subtitle-1 font-weight-bold mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatDateTime"
                                                                )(
                                                                  _vm
                                                                    .application
                                                                    .submitted_date
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("ConfirmationDialog", {
            attrs: {
              title: _vm.status.title,
              content: _vm.status.content,
              showProceedButton: true,
              showBackButton: true,
              delayOnProceed: false,
              proceedButton: "Ya",
              backButton: "Tidak",
            },
            on: {
              onProceed: function ($event) {
                return _vm.onProceed(_vm.status.id)
              },
            },
            model: {
              value: _vm.showConfirmationDialog,
              callback: function ($$v) {
                _vm.showConfirmationDialog = $$v
              },
              expression: "showConfirmationDialog",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }