<template>
  <v-row justify="center">
    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
      <div>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top>
          <v-row no-gutters align="center" justify="center">
            <v-col cols="1">
              <v-icon>{{ snackbar.icon }}</v-icon>
            </v-col>
            <v-col cols="10">
              <div class="mx-3">
                <p class="snackbar-title font-weight-bold mb-0">
                  {{ snackbar.title }}
                </p>
                <p class="snackbar-subtitle mb-0">
                  {{ snackbar.text }}
                </p>
              </div>
            </v-col>
            <v-col cols="1">
              <v-btn icon @click="snackbar.visible = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-snackbar>
        <v-row>
          <v-col cols="12" lg="8" md="6" sm="12">
            <b-card no-body>
              <b-card-text>
                <SecuredMedia
                  :src="fileSource"
                  width="100%"
                  :height="pdfHeight"
                ></SecuredMedia>
              </b-card-text>
            </b-card>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-tabs grow>
              <v-tab>Tindakan</v-tab>
              <v-tab>Maklumat</v-tab>
              <!-- <v-tab>Log</v-tab> -->

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-col class="py-2 px-0" cols="12" lg="12" md="12" sm="12">
                      <v-textarea
                        v-model="remarks"
                        outlined
                        name="remarks"
                        label="Tinggalkan komen (jika ada)"
                      ></v-textarea>
                    </v-col>
                    <v-row align="end" justify="end">
                      <v-btn
                        class="ml-4"
                        color="warning"
                        :disabled="disableKIVBtn"
                        :loading="isLoadingKIV"
                        @click="proceed(1, 'KIV', 'Anda pasti?')"
                      >
                        <span class="font-weight-bold">KIV</span>
                      </v-btn>
                      <v-btn
                        class="ml-4"
                        color="error"
                        :disabled="disabledRejectedBtn"
                        :loading="isLoadingReject"
                        @click="
                          proceed(
                            2,
                            'Tolak',
                            'Anda pasti ingin menolak permohonan ini?'
                          )
                        "
                      >
                        <span class="font-weight-bold">Tolak</span>
                      </v-btn>
                      <v-btn
                        class="mx-4"
                        color="primary"
                        :disabled="disableApprBtn"
                        :loading="isLoadingApprove"
                        @click="
                          proceed(
                            3,
                            'Lulus',
                            'Anda pasti ingin meluluskan permohonan ini?'
                          )
                        "
                      >
                        <span class="font-weight-bold">Lulus</span>
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat>
                  <v-card-text>
                    <Log :logs="logs"></Log>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-col>
                      <!-- <v-row>
                        <v-flex xs4 sm4 md4>
                          <v-col class="col pt-0 pb-0">
                            <p class="subtitle-1">No. Rujukan</p>
                          </v-col>
                        </v-flex>
                        <v-flex xs8 sm8 md8>
                          <v-col class="col pt-0 pb-0">
                            <p class="subtitle-1 font-weight-bold mb-0">
                              {{ application }}
                            </p>
                          </v-col>
                        </v-flex>
                      </v-row> -->

                      <v-row>
                        <v-flex xs4 sm4 md4>
                          <v-col class="col pt-0 pb-0">
                            <p class="subtitle-1">No. rujukan</p>
                          </v-col>
                        </v-flex>
                        <v-flex xs8 sm8 md8>
                          <v-col class="col pt-0 pb-0">
                            <p class="subtitle-1 font-weight-bold mb-0">
                              {{ application.ref_no }}
                            </p>
                          </v-col>
                        </v-flex>
                      </v-row>

                      <v-row>
                        <v-flex xs4 sm4 md4>
                          <v-col class="col pt-0 pb-0">
                            <p class="subtitle-1">Nama pemohon</p>
                          </v-col>
                        </v-flex>
                        <v-flex xs8 sm8 md8>
                          <v-col class="col pt-0 pb-0">
                            <p class="subtitle-1 font-weight-bold mb-0">
                              {{ application.member.full_name }}
                            </p>
                          </v-col>
                        </v-flex>
                      </v-row>

                      <v-row>
                        <v-flex xs4 sm4 md4>
                          <v-col class="col pt-0 pb-0">
                            <p class="subtitle-1">Tarikh permohonan dihantar</p>
                          </v-col>
                        </v-flex>
                        <v-flex xs8 sm8 md8>
                          <v-col class="col pt-0 pb-0">
                            <p class="subtitle-1 font-weight-bold mb-0">
                              {{ application.submitted_date | formatDateTime }}
                            </p>
                          </v-col>
                        </v-flex>
                      </v-row>

                    </v-col>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <Log :logs="logs"></Log>
                  </v-card-text>
                </v-card>
              </v-tab-item> -->
            </v-tabs>
          </v-col>
        </v-row>
      </div>

      <ConfirmationDialog
        v-model="showConfirmationDialog"
        :title="status.title"
        :content="status.content"
        :showProceedButton="true"
        :showBackButton="true"
        :delayOnProceed="false"
        proceedButton="Ya"
        backButton="Tidak"
        @onProceed="onProceed(status.id)"
      >
      </ConfirmationDialog>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_FILE_URL } from "@/core/services/store/file.module";
import {
  SHOW_APPLICATION,
  SET_APPLICATION,
} from "@/core/services/store/application.module";
import {
  APPROVE_APPLICATION,
  KIV_APPLICATION,
  APPROVER_REJECT_APPLICATION,
} from "@/core/services/store/approvedapplication.module";
import {
  ADD_APP_LOG,
  GET_APP_LOGS,
  SET_APP_LOGS,
} from "@/core/services/store/applogs.module";
import SecuredMedia from "@/view/pages/widgets/SecuredMedia.vue";

// import ConfirmationDialog from "@/view/pages/widgets/ConfirmationDialog.vue";
import Log from "@/view/pages/widgets/Log.vue";

export default {
  name: "ApplicationDetail",
  components: {
    Log,
    SecuredMedia,
    // ConfirmationDialog,
  },
  data() {
    return {
      status: {
        id: "",
        title: "",
        content: "",
      },
      fileSource: null,
      isLoadingKIV: false,
      isLoadingReject: false,
      isLoadingApprove: false,
      showConfirmationDialog: false,
      disableApprBtn: false,
      disableKIVBtn: false,
      disabledRejectedBtn: false,
      snackbar: {
        visible: false,
        title: "",
        text: "",
        color: "",
        icon: "",
      },
      thePDFHeight: "600px",
      remarks: "",
    };
  },
  computed: {
    ...mapGetters(["currentUser", "application", "logs"]),
    pdfHeight() {
      return this.thePDFHeight;
    }
  },
  async created() {
    // xxconsole.log(this.$route);
    this.fileSource = this.$route.params.url;
    // xxconsole.log(this.fileSource);
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      {
        title: "Senarai Batch",
        route: this.$route.query.prevPath,
      },
      // { title: "Pengesahan Permohonan" },
      {
        title: "Kelulusan",
        route: this.$route.fullPath,
      },
    ]);
    await this.$store
      .dispatch(SHOW_APPLICATION, this.$route.params.id)
      .then((response) => {
        if (response.success == true) {
          this.$store.commit(SET_APPLICATION, response.data);
        }
      });

    if (this.application) {
      await this.$store
        .dispatch(GET_APP_LOGS, {
          application_id: this.application.id,
        })
        .then((response) => {
          if (response.data.success == true) {
            this.$store.commit(SET_APP_LOGS, response.data.data);
          }
        });
    }
    this.application.approved_by.find((app) => {
      if (app.user_id == this.currentUser.id) {
        this.disableApprBtn = true;
      }
    });
    if (this.application.kiv == 1) {
      this.disableKIVBtn = true;
      this.disableApprBtn = false;
    }
    if (this.application.rejected == 1) {
      this.disableKIVBtn = true;
      this.disabledRejectedBtn = true;
    }
    this.resizePdf();
    window.addEventListener('resize', this.resizePdf);
    // xxconsole.log(this.$route);
    // this.fileSource = this.$route.params.url;
    // xxconsole.log(this.fileSource);

    // this.$store.dispatch(ADD_BREADCRUMB, [
    //   {
    //     title: "Kelulusan",
    //     route: this.$route.fullPath,
    //   },
    // ]);
    // await this.$store
    //   .dispatch(GET_FILE_URL, {
    //     application_id: this.$route.params.id,
    //     file: "bpa"
    //   })
    //   .then((response) => {
    //     // console.log(response);
    //     // console.log(response.data);

    //     // console.log(response.config.baseURL + response.config.url);
    //     // this.pdf = response.config.baseURL + response.data;
    //     // console.log(response.config.url);
    //     this.src = response.data.url;
    //   });
  },
  methods: {
    proceed(id, title, content) {
      this.status.id = id;
      this.status.title = title;
      this.status.content = content;
      this.showConfirmationDialog = true;
    },
    onProceed(id) {
      if (id) {
        switch (id) {
          case 1 || "1":
            this.KIVApplication();
            break;
          case 2 || "2":
            this.rejectApplication();
            break;
          case 3 || "3":
            this.approveApplication();
            break;
        }
      } else {
        this.snackbar.visible = true;
        this.snackbar.title = "Gagal";
        this.snackbar.text = "Sila muat semula halaman ini";
        this.snackbar.color = "warning";
        this.snackbar.icon = "mdi-alert-circle";
      }
    },
    approveApplication() {
      this.showConfirmationDialog = false;
      if (this.$route.params.id) {
        this.isLoadingApprove = true;
        setTimeout(() => {
          this.$store
            .dispatch(APPROVE_APPLICATION, {
              application_id: this.$route.params.id,
            })
            .then((response) => {
              // xxconsole.log(response);
              if (response.data.success == true) {
                this.isLoadingApprove = false;
                this.snackbar.visible = true;
                this.snackbar.title = "Berjaya";
                this.snackbar.text = "Permohonan berjaya diluluskan";
                this.snackbar.color = "primary";
                this.snackbar.icon = "mdi-check-circle";
                if (this.$route.query.prevPath) {
                  this.$router.push({
                    path: this.$route.query.prevPath,
                  });
                } else {
                  this.$router.push({
                    name: "Dashboard",
                  });
                }
              } else {
                this.isLoadingApprove = false;
                this.snackbar.visible = true;
                this.snackbar.title = "Gagal";
                this.snackbar.text = "Gagal";
                this.snackbar.color = "red accent-2";
                this.snackbar.icon = "mdi-alert-circle";
              }
            })
            .catch((err) => {
              this.isLoadingApprove = false;
              this.snackbar.visible = true;
              this.snackbar.title = "Gagal";
              this.snackbar.text = err;
              this.snackbar.color = "red accent-2";
              this.snackbar.icon = "mdi-alert-circle";
            });
        }, 2000);
      } else {
        alert("Permohonan tidak dijumpai");
      }
    },
    rejectApplication() {
      this.showConfirmationDialog = false;
      if (this.$route.params.id) {
        this.isLoadingReject = true;
        setTimeout(async () => {
          await this.$store
            .dispatch(APPROVER_REJECT_APPLICATION, {
              application_id: this.$route.params.id,
            })
            .then((response) => {
              // xxconsole.log(response);
              if (response.data.success == true) {
                this.isLoadingReject = false;
                this.snackbar.visible = true;
                this.snackbar.title = "Berjaya";
                this.snackbar.text =
                  "Permohonan berjaya ditolak kepada bahagian Processing";
                this.snackbar.color = "primary";
                this.snackbar.icon = "mdi-check-circle";

                if (this.$route.query.prevPath) {
                  this.$router.push({
                    path: this.$route.query.prevPath,
                  });
                } else {
                  this.$router.push({
                    name: "Dashboard",
                  });
                }
              } else {
                this.isLoadingReject = false;
                this.snackbar.visible = true;
                this.snackbar.title = "Gagal";
                this.snackbar.text = "Gagal";
                this.snackbar.color = "error";
                this.snackbar.icon = "mdi-alert-circle";
              }
            })
            .catch((err) => {
              this.isLoadingReject = false;
              this.snackbar.visible = true;
              this.snackbar.title = "Gagal";
              this.snackbar.text = "Gagal";
              this.snackbar.color = "error";
              this.snackbar.icon = "mdi-alert-circle";
            });
        }, 2000);
      }
    },
    KIVApplication() {
      this.showConfirmationDialog = false;
      if (this.$route.params.id) {
        this.isLoadingKIV = true;
        setTimeout(async () => {
          await this.$store
            .dispatch(KIV_APPLICATION, {
              application_id: this.$route.params.id,
              remarks: this.remarks,
            })
            .then((response) => {
              // xxconsole.log(response);
              if (response.data.success == true) {
                this.isLoadingKIV = false;
                // this.snackbar.visible = true;
                // this.snackbar.title = "Berjaya";
                // this.snackbar.text = response.data.message;
                // this.snackbar.color = "primary";
                // this.snackbar.icon = "mdi-check-circle";

                if (this.$route.query.prevPath) {
                  this.$router.push({
                    path: this.$route.query.prevPath,
                  });
                } else {
                  this.$router.push({
                    name: "Dashboard",
                  });
                }
              } else {
                this.isLoadingKIV = false;
                this.snackbar.visible = true;
                this.snackbar.title = "Gagal";
                this.snackbar.text = "Gagal";
                this.snackbar.color = "error";
                this.snackbar.icon = "mdi-alert-circle";
              }
            })
            .catch((err) => {
              this.isLoadingKIV = false;
              this.snackbar.visible = true;
              this.snackbar.title = "Gagal";
              this.snackbar.text = "Gagal";
              this.snackbar.color = "error";
              this.snackbar.icon = "mdi-alert-circle";
            });
        }, 2000);
      }
    },
    resizePdf() {
      this.thePDFHeight = (window.innerHeight - 250) + 'px';
    },
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.fileSource);
    window.removeEventListener('resize', this.resizePdf);
    // xxconsole.log("Before Destroy: ApplicationDetail")
    // this.$store.dispatch(POP_BREADCRUMB);
  },
};
</script>
<style>
.v-text-field--outlined {
  font-size: 12px;
}

.v-text-field--outlined label {
  font-size: 12px;
}
</style>